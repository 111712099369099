* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: 'Comfortaa';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #16171d;
	color: #4d7e9c;
}

.home-container {
	height: 100vh;
	display: grid;
	grid-template-rows: max-content 1fr max-content;
}

header {
	display: flex;
	justify-content: space-between;
}

main,
#about-section {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 2rem 0rem;
}

.logo {
	height: 8rem;
	display: flex;
	align-items: center;
	margin-left: 3rem;
}

h1,
.logo-span {
	font-size: 5rem;
}

.portfolio-card-link {
	color: orange;
	text-decoration: none;
}

.contact-form-title,
.logo-span,
.who-am-i,
.required-field,
.nav-links:hover {
	color: orange;
}

.navigation {
	height: 8rem;
	display: flex;
	list-style: none;
	justify-content: flex-end;
	align-items: center;
}

.navigation li {
	margin-right: 3rem;
	font-size: 1.5rem;
	font-weight: 400;
	position: relative;
}

.navigation li::after {
	content: "";
	position: absolute;
	background-color: orange;
	height: 2px;
	width: 0;
	left: 0;
	bottom: -8px;
	transition: 0.5s;
}

.navigation li:hover::after {
	width: 100%;
}

.hero-text,
.hero-image {
	display: grid;
	place-content: center;
  animation: fade-in 2s;
}

.hero-message {
	font-size: 5rem;
	width: 25ch;
	line-height: 1.5;
}

.about-me-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.about-me-text {
	width: 60ch;
	line-height: 2;
	font-size: 1.5rem;
  animation: fade-in 2s;
}

.profile-image-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.profile-image {
	border-radius: 50%;
	border: 2px solid orange;
	animation: fade-in 2s;
}

.cv-download-link {
	text-decoration: none;
	color: orange;
	font-weight: bold;
	font-size: 2rem;
	margin-top: 4rem;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

#portfolio-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
	place-content: center;
	gap: 6rem;
}

.portfolio-card {
	display: grid;
	grid-template-rows: 2fr 1fr;
	height: 400px;
	border: 1px solid orange;
	border-radius: 16px;
	animation: fade-in 2s;
	transition: 0.5s;
}

.portfolio-card-img {
	width: 100%;
	height: 100%;
	border-radius: 16px 16px 0 0;
	border-bottom: 1px solid orange;
	object-fit: cover;
}

.portfolio-card-descr {
	display: grid;
	place-content: center;
	color: #4d7e9c;
}

.footer-right {
	margin-right: 3rem;
}

.footer-left {
	margin-left: 3rem;
}

.social-icon-github {
	margin-right: 2rem;
}

.nav-links {
	text-decoration: none;
	color: #4d7e9c;
	transition: 0.5s;
}

#contact-form {
	margin: auto;
	padding: 3rem 4rem;
	width: 45rem;
	background: #4d7e9c1a;
	display: grid;
	grid-template-rows: repeat(9, min-content);
	border-radius: 20px;
  animation: fade-in 2s;
}

.contact-form-title {
	margin-bottom: 4rem;
	font-size: 2.5rem;
	text-align: center;
}

.form-label {
	display: block;
	font-size: 1.5rem;
	margin-bottom: 0.5rem;
}

#contact-form-button-container {
	display: flex;
	place-content: center;
}

.form-input {
	display: block;
	width: 60%;
	height: 2.2rem;
	padding: 0.5rem;
	margin-bottom: 2rem;
	font-size: 1rem;
	border-radius: 6px;
	border-style: none;
}

textarea {
	resize: none;
	width: 100%;
	font-size: 1.2rem;
	padding: 0.5rem;
	font-family: sans-serif;
}

button {
	margin-top: 5rem;
	padding: 0;
	width: 40%;
	height: 3rem;
	font-size: 1.2rem;
	font-weight: bold;
	font-family: 'Comfortaa';
	background: orange;
	color: #16171d;
	border-style: none;
	border-radius: 30px;
	transition: 0.5s;
}

button:hover {
	background: #4d7e9c;
	color: orange;
}

@media screen and (max-width: 1920px) {
	.hero-message {
		font-size: 3rem;
	}

	.hero-illustration {
		width: 500px;
	}

	.about-me-text {
		font-size: 1.2rem;
	}

	.profile-image {
		width: 400px;
	}

	.cv-download-link {
		font-size: 1.5rem
	}

	#contact-form {
		width: 35rem;
	}

	.contact-form-title {
		margin-bottom: 2rem;
	}

	.form-input {
		margin-bottom: 1rem;
		width: 80%;
	}

	.message-input {
		height: 180px;
	}

	button {
		margin-top: 2rem;
	}
}

@media screen and (max-width: 1280px) {
	h1,
	.logo-span {
		font-size: 3.5rem;
	}

	.navigation li {
		font-size: 1.2rem;
	}

	.hero-message {
		font-size: 2.5rem;
	}

	.hero-illustration {
		width: 400px;
	}

	.about-me-text {
		font-size: 1rem;
		margin-left: 3rem;
	}

	.profile-image {
		width: 350px;
	}

	.cv-download-link {
		font-size: 1.2rem
	}

	#contact-form {
		width: 30rem;
		padding: 2rem 3.5rem;
	}

	.contact-form-title {
		font-size: 2rem;
		margin-bottom: 1rem;
	}

	.form-label {
		font-size: 1rem;
	}

	.form-input {
		margin-bottom: 0.5rem;
		height: 60%;
		width: 80%;
	}

	.message-input {
		height: 150px;
	}

	button {
		margin-top: 2rem;
	}
}

@media screen and (max-width: 900px) {
	header {
		display: grid;
		place-content: center;
	}

	.logo {
		display: grid;
		margin: 0;
		place-items: center;
	}

	.logo,
	.navigation {
		height: 6rem;
		display: grid;
		grid-auto-flow: column;
		justify-content: center;
	}

	.navigation li {
		margin-right: 0;
		padding: 0.5rem 1rem;
	}

	main,
	#about-section {
		display: flex;
		flex-direction: column;
		place-content: center;
		justify-content: space-evenly;
		margin: auto;
		gap: 2rem;
	}

	main {
		gap: 4rem;
	}

	.hero-illustration {
		width: 300px;
	}

	.hero-message {
		font-size: 1.8rem;
		width: 400px;
		text-align: center;
	}

	.cv-download-link {
		margin: 0;
	}

	footer {
		display: flex;
		flex-direction: column;
		margin: 1rem, 0;
	}

	.footer-right,
	.social-icon-github {
		margin: 0;
	}

	.footer-right {
		display: flex;
		gap: 2rem;
	}

	.profile-image-container {
		display: flex;
		flex-direction: row;
		gap: 2rem;
	}

	.profile-image {
		width: 100px
	}

	.about-me-text {
		margin: 0;
		width: 40ch;
	}

	#about-section {
		width: 100%;
	}

	#portfolio-container {
		gap: 3rem;
	}

	.portfolio-card {
		height: 350px;
	}

	#contact-form {
		width: 24rem;
	}

}
